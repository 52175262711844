import React, { useImperativeHandle } from "react";
import { Form, FormInputType, message as alert } from "@wellth/web-ui";
import moment, { Moment } from "moment-timezone";
import { dayIsInThePast } from "utils/date";
import Footer from "./components/Footer";

const FormInputs = {
  DAY_OF_WEEK: "dayOfWeek",
  TIME: "time",
  SCHEDULE_JOB: "scheduleJob",
  DELETE_AFTER: "deleteAfter",
  SMS_REMINDER_MINUTES_ADVANCE: "reminderMinutesAdvance",
};

export type DeleteDialysisApptFormProps = {
  scheduleJob: string;
  time: string;
  dayOfWeek: string;
  startDate: string;
  endDate: string;
  deleteDialysisAppt: (scheduleJob: string, deleteAfter: Moment) => void;
  reminderMinutesAdvance: number;
};

export type DeleteDialysisApptFormInputs = {
  scheduleJob: string;
  deleteAfter: Moment;
};

export type UpdateDialysisAppointmentReminderInputs = {
  scheduleJob: string;
  reminderMinutesAdvance: number;
};

const shouldPreventPastDates = (current: Date, timezone: string) =>
  current && dayIsInThePast(current, timezone);

const DeleteDialysisApptForm: React.RefForwardingComponent<
  any,
  DeleteDialysisApptFormProps
> = (props, ref) => {
  const formRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    getDialysisAppt: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <>
      <Form
        wrappedComponentRef={formRef}
        mapPropsToFields={(_props, createFormField) => ({
          time: createFormField({ value: props.time }),
          scheduleJob: createFormField({
            value: props.scheduleJob,
          }),
          dayOfWeek: createFormField({
            value: props.dayOfWeek,
          }),
          reminderMinutesAdvance: createFormField({
            value: props.reminderMinutesAdvance,
          }),
        })}
        inputs={[
          {
            type: FormInputType.Text,
            title: "Day of Week",
            key: FormInputs.DAY_OF_WEEK,
            readOnly: true,
            disabled: true,
          },
          {
            type: FormInputType.Text,
            title: "Time",
            key: FormInputs.TIME,
            readOnly: true,
            disabled: true,
          },
          {
            type: FormInputType.Info,
            title: "Schedule Id",
            key: FormInputs.SCHEDULE_JOB,
          },
          {
            type: FormInputType.InputNumber,
            title:
              "Send SMS Reminder X Minutes Before Appointment (leave blank for no SMS Reminder)",
            key: FormInputs.SMS_REMINDER_MINUTES_ADVANCE,
            decoratorOptions: { rules: [{ required: false }] },
          },
          {
            type: FormInputType.Date,
            format: "LL",
            title: "Deletion - Delete appointments on this day and forward",
            key: FormInputs.DELETE_AFTER,
            decoratorOptions: { rules: [{ required: false }] },
            disabledDate: (current) =>
              shouldPreventPastDates(current.toDate(), moment.tz.guess()),
          },
        ]}
      />
      <Footer
        deleteDialysisAppt={() => {
          const { form } = formRef.current.props;
          const deleteAfterDate = form.getFieldValue(FormInputs.DELETE_AFTER);

          if (!deleteAfterDate) {
            void alert.error(
              "Please select a date to delete appointments from.",
            );
            return;
          }

          props.deleteDialysisAppt(props.scheduleJob, moment(deleteAfterDate));
        }}
        disabled={false}
      />
    </>
  );
};

export default React.forwardRef(DeleteDialysisApptForm);
