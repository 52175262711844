import React, { useRef } from "react";
import capitalize from "lodash/capitalize";
import { Modal } from "components/Modal";
import ChangeStatusForm, {
  ChangeStatusFormProps,
} from "components/ChangeStatusForm";
import { AdminPrivilege } from "constants/adminPrivilege";

export interface ChangeStatusFormModalProps extends ChangeStatusFormProps {
  shortId: string;
  programCode: string;
  userType: "member" | "prospect";
  submit: (formValues: any) => Promise<void>;
  goBack: () => void;
  adminRole: AdminPrivilege;
  loading?: boolean;
}

export const ChangeStatusFormModal: React.FC<ChangeStatusFormModalProps> = ({
  shortId,
  programCode,
  userType,
  submit,
  goBack,
  status,
  adminRole,
  loading,
}: ChangeStatusFormModalProps) => {
  const formRef = useRef(null);

  return (
    <Modal
      okText="Submit"
      loading={false}
      title={`Change ${shortId} ${capitalize(
        userType,
      )} Status for ${programCode}`}
      visible
      onCancel={goBack}
      submissionLoading={loading}
      onOk={async () => submit(await formRef.current.getChangeStatusReason())}
    >
      <ChangeStatusForm status={status} adminRole={adminRole} ref={formRef} />
    </Modal>
  );
};

export default ChangeStatusFormModal;
