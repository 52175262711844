import React from "react";
import { message as alert } from "@wellth/web-ui";
import {
  ChangeStatusFormModal,
  ChangeStatusFormModalProps,
} from "components/ChangeStatusFormModal";
import {
  useChangeProspectStatusMutation,
  GetProspectByIdDocument as prospectByIdQuery,
} from "hooks/graphql";

export interface ChangeProspectStatusModalProps
  extends Omit<ChangeStatusFormModalProps, "userType" | "submit"> {
  id: string;
}

export const ChangeProspectStatusModal: React.FC<
  ChangeProspectStatusModalProps
> = ({
  id,
  goBack,
  shortId,
  programCode,
  status,
  adminRole,
}: ChangeProspectStatusModalProps) => {
  const [changeProspectStatus, { loading }] = useChangeProspectStatusMutation({
    refetchQueries: [{ query: prospectByIdQuery, variables: { id } }],
    onError: ({ message }) => alert.error(message),
    onCompleted: goBack,
  });

  return (
    <ChangeStatusFormModal
      adminRole={adminRole}
      programCode={programCode}
      status={status}
      shortId={shortId}
      goBack={goBack}
      userType="prospect"
      submit={async ({ type, reason, reasonDetail, note }) => {
        await changeProspectStatus({
          variables: { id, status: type, reason, reasonDetail, note },
        });
      }}
      loading={loading}
    />
  );
};

export default ChangeProspectStatusModal;
