import React from "react";
import { Popconfirm, Button } from "@wellth/web-ui";
import { BUTTON_CONTAINER_STYLES } from "containers/MemberProfile/styles";

interface DeleteButtonProps {
  deleteDialysisAppt: () => void;
  disabled: boolean;
  loading?: boolean;
}
interface Props extends DeleteButtonProps {}

const DeleteButton = ({
  disabled,
  loading,
  deleteDialysisAppt,
}: DeleteButtonProps) => (
  <>
    {disabled ? (
      <Button type="danger" disabled={disabled} loading={loading}>
        Delete reminder
      </Button>
    ) : (
      <Popconfirm
        title="Are you sure?"
        okText="Yes"
        cancelText="No"
        onConfirm={deleteDialysisAppt}
      >
        <Button type="danger" disabled={disabled} loading={loading}>
          Delete dialysis appointment
        </Button>
      </Popconfirm>
    )}
  </>
);

const Footer = ({ deleteDialysisAppt, disabled, loading }: Props) => (
  <div style={BUTTON_CONTAINER_STYLES}>
    <DeleteButton
      deleteDialysisAppt={deleteDialysisAppt}
      disabled={disabled}
      loading={loading}
    />
  </div>
);

export default Footer;
