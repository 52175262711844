import React, { useImperativeHandle } from "react";
import { Form, FormInputType } from "@wellth/web-ui";
import moment, { Moment } from "moment-timezone";
import { dayIsInThePast } from "utils/date";

const FormInputs = {
  START_DATE: "startDate",
  END_DATE: "endDate",
  TIME: "time",
  REMINDER_MINUTES_ADVANCE: "reminderMinutesAdvance",
};

export type CreateDialysisApptFormProps = {
  placeholder: string;
};

export type CreateDialysisApptFormInputs = {
  startDate: Moment;
  endDate: Moment;
  time: Moment;
  reminderMinutesAdvance?: number;
};

const shouldPreventPastDates = (current: Date, timezone: string) =>
  current && dayIsInThePast(current, timezone);

const CreateDialysisApptForm: React.RefForwardingComponent<any, any> = (
  _,
  ref,
) => {
  const formRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    getDialysisAppt: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      wrappedComponentRef={formRef}
      mapPropsToFields={(_props, createFormField) => ({
        reminderMinutesAdvance: createFormField({
          value: 120,
        }),
      })}
      inputs={[
        {
          type: FormInputType.Date,
          format: "LL",
          title: "Start Date (repeating weekly from this date)",
          key: FormInputs.START_DATE,
          decoratorOptions: {
            rules: [{ required: true, message: "Start Date is required" }],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), moment.tz.guess()),
        },
        {
          type: FormInputType.Date,
          title: "End Date",
          format: "LL",
          key: FormInputs.END_DATE,
          decoratorOptions: {
            rules: [{ required: true, message: "End Date is required" }],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), moment.tz.guess()),
        },
        {
          type: FormInputType.Time,
          title: "Time",
          key: FormInputs.TIME,
          decoratorOptions: {
            rules: [{ required: true, message: "Time is required" }],
          },
        },
        {
          type: FormInputType.InputNumber,
          title:
            "Send SMS Reminder X Minutes Before Appointment (leave blank for no SMS Reminder)",
          key: FormInputs.REMINDER_MINUTES_ADVANCE,
          defaultValue: 120,
          decoratorOptions: {
            rules: [
              {
                required: false,
              },
            ],
          },
        },
      ]}
    />
  );
};

export default React.forwardRef(CreateDialysisApptForm);
