import React, { useRef } from "react";
import { Modal } from "components/Modal";
import { message as alert } from "antd";
import {
  DialysisAppointmentSchedulesDocument,
  useDeleteDialysisMutation,
  useDialysisAppointmentScheduleQuery,
  DialysisAppointmentScheduleDocument,
  useUpdateDialysisAppointmentReminderInputMutation,
} from "hooks/graphql";
import DeleteDialysisApptForm, {
  UpdateDialysisAppointmentReminderInputs,
} from "components/DeleteDialysisApptForm";
import { Moment } from "moment";

export interface DeleteDialysisApptFormModalProps {
  cancel: () => void;
  dialysisId: string;
  memberId: string;
}

export const DeleteDialysisApptFormModal: React.FC<
  DeleteDialysisApptFormModalProps
> = ({ cancel, dialysisId, memberId }: DeleteDialysisApptFormModalProps) => {
  const formRef = useRef(null);

  const {
    loading,
    data: {
      dialysisAppointmentSchedule: {
        scheduleJob,
        time,
        dayOfWeek,
        startDate,
        endDate,
        reminderMinutesAdvance,
      } = {},
    } = {},
  } = useDialysisAppointmentScheduleQuery({
    onError: ({ message }) => alert.error(message),
    variables: {
      scheduleJob: dialysisId,
    },
  });

  const [
    deleteDialysis,
    { loading: submissionLoading },
  ] = useDeleteDialysisMutation({
    onCompleted: cancel,
    onError: ({ message }) => alert.error(message),
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: DialysisAppointmentSchedulesDocument, variables: { memberId } },
    ],
  });
  const [
    updateDialysisAppt,
    { loading: updateDialysisApptLoading },
  ] = useUpdateDialysisAppointmentReminderInputMutation({
    onCompleted: cancel,
    onError: ({ message }) => alert.error(message),
    refetchQueries: [
      {
        query: DialysisAppointmentScheduleDocument,
        variables: { scheduleJob: dialysisId },
      },
    ],
  });

  const submit = async (props: UpdateDialysisAppointmentReminderInputs) => {
    await updateDialysisAppt({
      variables: {
        input: {
          scheduleJob: props.scheduleJob,
          reminderMinutesAdvance: props.reminderMinutesAdvance,
        },
      },
    });
  };

  const deleteDialysisWithButton = async (
    scheduleJobId: string,
    deleteAfter: Moment,
  ) => {
    await deleteDialysis({
      variables: {
        input: {
          scheduleJob: scheduleJobId,
          fromDate: deleteAfter.format("YYYY-MM-DD"),
        },
      },
    });
  };

  return (
    <Modal
      title="Edit Dialysis Appointment"
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading || updateDialysisApptLoading}
      onCancel={cancel}
      width={700}
      // change to submit edit dialysis appt
      onOk={async () => submit(await formRef.current.getDialysisAppt())}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <DeleteDialysisApptForm
        ref={formRef}
        scheduleJob={scheduleJob}
        time={time}
        dayOfWeek={dayOfWeek}
        startDate={startDate}
        endDate={endDate}
        reminderMinutesAdvance={reminderMinutesAdvance}
        deleteDialysisAppt={deleteDialysisWithButton}
      />
    </Modal>
  );
};

export default DeleteDialysisApptFormModal;
